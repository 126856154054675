<template>
  <div id="app">
    <NavComponent />
    <main class="contenedor-general">
      <router-view/>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import NavComponent from './components/templates/NavComponent.vue';
import FooterComponent from './components/templates/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    NavComponent,
    FooterComponent
  },
  data() {
    return {
      deferredPrompt: null,
    };
  },
  mounted() {
    window.addEventListener("beforeinstallprompt", (event) => {
      // Previene el comportamiento predeterminado
      event.preventDefault();
      this.deferredPrompt = event; // Guarda el evento para usarlo más tarde
      // console.log("Instalación disponible");
      // Aquí puedes mostrar un botón o UI para instalar la app
    });
  },
  methods: {
    installApp() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt(); // Muestra el prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          console.log(choiceResult.outcome); // Log resultado
          this.deferredPrompt = null; // Limpia el prompt diferido
        });
      }
    },
  },
}
</script>
<style lang="sass">
.contenedor-general
  max-width: 1400px
  width: 95%
  margin: 0 auto
  padding: 30px 0
  display: flex
  // min-width: 95%
  // align-items: center
  // justify-content: center
  min-height: 80vh
  .home
    width: 100%
  
</style>