import { createApp } from 'vue';
import App from './App.vue';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./registerServiceWorker";
import router from './router';
import { createStore } from 'vuex';
import store from "./store";
import * as Sentry from "@sentry/vue";
import VueKonva from 'vue-konva';

// createApp(App).use(store).use(router).mount("#app");
// const app = createApp(App);
// app.use(router);
// app.mount('#app');

const app = createApp(App);


Sentry.init({
  app,
  dsn: "https://896b049f932ade4f20e6731075c6a18c@o4507252044070912.ingest.us.sentry.io/4507252058750976",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// Asegúrate de usar store aquí
app.use(store);
app.use(VueKonva);
// Y si estás usando Vue Router
app.use(router);

app.use(Toast, {
    // Opciones globales
    position: 'top-right',
    timeout: 5000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: 'button',
    icon: true,
    rtl: false
  });


  // Hacer que Toastify esté disponible globalmente
app.config.globalProperties.$toast = app.config.globalProperties.$toast;

app.mount('#app');


