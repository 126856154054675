<template>
  <div class="buscador">
    <div class="iconos">
      <span v-if="hasCamera" class="icono-cam" @click="checkPermissionsAndOpenCamera" >
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 13C11.6569 13 13 11.6569 13 10C13 8.34315 11.6569 7 10 7C8.34315 7 7 8.34315 7 10C7 11.6569 8.34315 13 10 13Z" fill="#8246AF"/>
          <path d="M7 0L5.17 2H2C0.9 2 0 2.9 0 4V16C0 17.1 0.9 18 2 18H18C19.1 18 20 17.1 20 16V4C20 2.9 19.1 2 18 2H14.83L13 0H7ZM10 15C7.24 15 5 12.76 5 10C5 7.24 7.24 5 10 5C12.76 5 15 7.24 15 10C15 12.76 12.76 15 10 15Z" fill="#8246AF"/>
        </svg>
        <input type="file" ref="fileInput" accept="image/*" style="display: none" @change="handleImage">
      </span>      
        <!-- Botón para abrir la galería -->
      <span class="icono-file" @click="triggerFileInput">
            <svg width="20" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="#8246AF" d="M464 448H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h416c26.5 0 48 21.5 48 48v288c0 26.5-21.5 48-48 48zM112 120c-30.9 0-56 25.1-56 56s25.1 56 56 56 56-25.1 56-56-25.1-56-56-56zM64 384h384V272l-87.5-87.5c-4.7-4.7-12.3-4.7-17 0L208 320l-55.5-55.5c-4.7-4.7-12.3-4.7-17 0L64 336v48z"/>
            </svg>
            <input
              type="file"
              ref="galleryFileInput"
              accept="image/*"
              style="display: none"
              @change="handleImageSelection"
            />


      </span>
      
    </div>
    <input type="file" ref="fileInput" accept="image/*" style="display: none" @change="handleImage">
    <input type="text" class="buscador-input" v-model="searchTerm" @keydown.enter="performSearch">
    <span class="icono-buscar" @click="performSearch">
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.7233 13.8365H14.7296L14.3774 13.4969C15.6101 12.0629 16.3522 10.2013 16.3522 8.1761C16.3522 3.66038 12.6918 0 8.1761 0C3.66038 0 0 3.66038 0 8.1761C0 12.6918 3.66038 16.3522 8.1761 16.3522C10.2013 16.3522 12.0629 15.6101 13.4969 14.3774L13.8365 14.7296V15.7233L20.1258 22L22 20.1258L15.7233 13.8365ZM8.1761 13.8365C5.04402 13.8365 2.51572 11.3082 2.51572 8.1761C2.51572 5.04402 5.04402 2.51572 8.1761 2.51572C11.3082 2.51572 13.8365 5.04402 13.8365 8.1761C13.8365 11.3082 11.3082 13.8365 8.1761 13.8365Z" fill="#3C1053"/>
      </svg>
    </span>
    <!-- Modal para la cámara -->
              
    <div v-if="cameraActive" class="camera-screen">
      <div class="video-container">
        <video ref="video" autoplay playsinline></video>
        <div class="overlay"></div>
        <div class="overlay-mask"></div>
        <button @click="closeCamera" class="close-camera-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </button>
        <button @click="switchCamera" class="switch-camera-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M213.1 64.8L202.7 96 128 96c-35.3 0-64 28.7-64 64l0 256c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64l-74.7 0L426.9 64.8C420.4 45.2 402.1 32 381.4 32L258.6 32c-20.7 0-39 13.2-45.5 32.8zM448 256c0 8.8-7.2 16-16 16l-76.7 0c-6.2 0-11.3-5.1-11.3-11.3c0-3 1.2-5.9 3.3-8L371 229c-13.6-13.4-31.9-21-51-21c-19.2 0-37.7 7.6-51.3 21.3L249 249c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l19.7-19.7C257.4 172.7 288 160 320 160c31.8 0 62.4 12.6 85 35l23.7-23.7c2.1-2.1 5-3.3 8-3.3c6.2 0 11.3 5.1 11.3 11.3l0 76.7zM192 320c0-8.8 7.2-16 16-16l76.7 0c6.2 0 11.3 5.1 11.3 11.3c0 3-1.2 5.9-3.3 8L269 347c13.6 13.4 31.9 21 51 21c19.2 0 37.7-7.6 51.3-21.3L391 327c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-19.7 19.7C382.6 403.3 352 416 320 416c-31.8 0-62.4-12.6-85-35l-23.7 23.7c-2.1 2.1-5 3.3-8 3.3c-6.2 0-11.3-5.1-11.3-11.3l0-76.7z"/></svg>
        </button>
      
      </div>
      <button @click="takePicture" class="capture-button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M149.1 64.8L138.7 96 64 96C28.7 96 0 124.7 0 160L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64l-74.7 0L362.9 64.8C356.4 45.2 338.1 32 317.4 32L194.6 32c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>
      </button>
     
    </div>
    <!-- Modal para manipular la imagen -->
    <div v-if="showImageEditor" class="modal-overlay-file">
      <div class="image-editor">
        
        <v-stage
          ref="stage"
          :config="{ width: visibleCanvasSize, height: visibleCanvasSize }"
          @wheel="handleWheel"
        >
          <v-layer>
            <v-image
              ref="image"
              :config="{
                image: imageElement,
                x: offsetX,
                y: offsetY,
                scaleX: zoom,
                scaleY: zoom,
                draggable: true,
                dragBoundFunc: constrainDrag
              }"
              @dragmove="updatePosition"
            />
          </v-layer>
        </v-stage>
        <div class="overlay-mask"></div>
        <button @click="closeFile" class="close-file-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </button>
        <button @click="resetEditor" class="return-button">
          
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M125.7 160l50.3 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L48 224c-17.7 0-32-14.3-32-32L16 64c0-17.7 14.3-32 32-32s32 14.3 32 32l0 51.2L97.6 97.6c87.5-87.5 229.3-87.5 316.8 0s87.5 229.3 0 316.8s-229.3 87.5-316.8 0c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0c62.5 62.5 163.8 62.5 226.3 0s62.5-163.8 0-226.3s-163.8-62.5-226.3 0L125.7 160z"/></svg>
        </button>
      </div>
      <div class="confirm-radio-file">
        <input
            type="range"
            min="1"
            max="3"
            step="0.1"
            v-model.number="zoom"
            @input="updateZoom"
          />
        
        <button @click="confirmCrop" class="capture-button-cut">
          
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480l0-83.6c0-4 1.5-7.8 4.2-10.8L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z"/></svg>
        </button>
        
      </div>
    </div>

    <!-- Modal para procesar la imagen -->
    <transition name="fade">
      <div v-if="showModal" class="modal-overlay">
        <div class="modal">
          <transition name="fade">  

            <div class="caja-img">
              <img :src="originalImage" alt="Original Image" class="base-image">
              <transition name="fade">
                <img v-if="processedImage" :src="processedImage" :key="currentImageKey"
                     :class="{'image-transition': true, 'persist-opacity': imageLoaded}"
                     alt="Processed Image">
              </transition>
            </div>
          </transition>
          <p>{{ modalMessage }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { Stage, Layer, Image as KonvaImage } from "vue-konva";
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      searchTerm: '',
      showModal: false,
      modalMessage: '',
      originalImage: '',
      processedImage: null,
      currentImageKey: 0,
      imageLoaded: false,
      messages: ['','Procesando imagen', 'Buscando productos similares', 'Mostrando productos'],
      currentMessageIndex: 0,
      cameraActive: false,
      videoStream: null,
      useRearCamera: true, // Controla si se usa la cámara trasera
      hasCamera: false, 
      showImageEditor: false,
      imageFile: null,
      zoom: 1,
      offsetX: 0,
      offsetY: 0,
      canvasContext: null,
      image: null,
      hammerInstance: null,
      imageElement: null, // Elemento de imagen cargada
      canvasSize: 512, // Tamaño del canvas (512x512)
      zoom: 1, // Nivel de zoom inicial
      offsetX: 0, // Desplazamiento en X
      offsetY: 0, // Desplazamiento en Y
    };
  },
  async mounted() {
    // Verificar disponibilidad de cámaras al cargar el componente
    await this.checkCameraAvailability();
    window.addEventListener("resize", this.onWindowResize);
    this.adjustCanvasSize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  components: {
    VStage: Stage,
    VLayer: Layer,
    VImage: KonvaImage,
  },
  computed: {
    visibleCanvasSize() {
      const screenSize = Math.min(window.innerWidth, window.innerHeight);
      return Math.max(Math.min(screenSize, 512), 300); // Mínimo 300px para pantallas pequeñas
    },
},

  methods: {
    ...mapActions(['setImageSearchResults', 'clearImageSearchResults']),
    async checkCameraAvailability() {
      try {
        const storedCameraAvailability = localStorage.getItem('hasRearCamera');

        // Verificar si ya existe información en localStorage
        if (storedCameraAvailability !== null) {
          this.hasRearCamera = JSON.parse(storedCameraAvailability);
          this.hasCamera = true; // Mostrar el ícono si hay cámaras detectadas previamente
          return;
        }

        // Enumerar dispositivos conectados
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === 'videoinput'
        );

        if (videoDevices.length === 0) {
          this.hasCamera = false; // Ocultar ícono si no hay cámaras disponibles
          localStorage.setItem('hasRearCamera', JSON.stringify(false));
          return;
        }

        // Buscar cámara trasera explícitamente
        const rearCamera = videoDevices.find((device) =>
          device.label.toLowerCase().includes('back') ||
          device.label.toLowerCase().includes('rear')
        );

        this.hasRearCamera = !!rearCamera; // Convertir a booleano
        this.hasCamera = true; // Mostrar ícono ya que al menos una cámara está disponible

        // Guardar resultado en localStorage
        localStorage.setItem('hasRearCamera', JSON.stringify(this.hasRearCamera));
      } catch (error) {
        console.error('Error al verificar cámaras:', error);
        this.hasCamera = false; // Ocultar ícono en caso de error
        localStorage.setItem('hasRearCamera', JSON.stringify(false));
      }
    },
    openCamera() {
      this.cameraActive = true;
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          this.videoStream = stream;
          this.$refs.video.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error al acceder a la cámara:", error);
        });
    },
      async startCamera() {
        try {
          const constraints = {
            video: {
              facingMode: this.useRearCamera ? "environment" : "user",
            },
          };

          const stream = await navigator.mediaDevices.getUserMedia(constraints);

          this.videoStream = stream;
          this.cameraActive = true;

          this.$refs.video.srcObject = stream;
          this.$refs.video.play();
        } catch (error) {
          console.error("Error al iniciar la cámara:", error);
        }
      },
    switchCamera() {
      // Cambiar entre cámara frontal y trasera
      this.useRearCamera = !this.useRearCamera;

      // Reiniciar la cámara con la nueva configuración
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
      }

      this.startCamera();
    },

  
    async checkPermissionsAndOpenCamera() {
  try {
    // Obtener todos los dispositivos de entrada
    let devices = await navigator.mediaDevices.enumerateDevices();
    let videoDevices = devices.filter((device) => device.kind === 'videoinput');

    if (videoDevices.length === 0) {
      console.warn('No se detectaron cámaras conectadas. Verifica que el dispositivo tenga una cámara disponible.');
      alert('No se detectaron cámaras disponibles. Verifica que el dispositivo tenga una cámara.');
      return;
    }

    let stream;

    // Intentar abrir la cámara trasera directamente con facingMode
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { exact: 'environment' } },
      });
    } catch (facingModeError) {
      console.warn('No se pudo abrir la cámara trasera directamente con facingMode:', facingModeError);

      // Buscar explícitamente una cámara trasera por su etiqueta
      const rearCamera = videoDevices.find((device) =>
        device.label.toLowerCase().includes('back') || device.label.toLowerCase().includes('rear')
      );

      // Si hay una cámara trasera identificada, intentar usarla
      if (rearCamera) {
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: { exact: rearCamera.deviceId } },
          });
        } catch (rearCameraError) {
          console.warn('No se pudo abrir la cámara trasera por deviceId:', rearCameraError);
        }
      }
    }

    // Si no se pudo abrir ninguna cámara trasera, usar la primera cámara disponible
    if (!stream) {
      console.warn('No se pudo abrir la cámara trasera. Intentando abrir la primera cámara disponible.');
      try {
        stream = await navigator.mediaDevices.getUserMedia({ video: true });
      } catch (defaultCameraError) {
        console.error('No se pudo abrir la cámara predeterminada:', defaultCameraError);
        alert('No se pudo abrir ninguna cámara disponible. Verifica los permisos y el dispositivo.');
        return;
      }
    }

    // Configurar el flujo de video en el DOM si se obtuvo un stream
    if (stream) {
      this.videoStream = stream;
      this.cameraActive = true;

      this.$nextTick(() => {
        if (this.$refs.video) {
          this.$refs.video.srcObject = stream;
          this.$refs.video.onloadedmetadata = () => {
            this.$refs.video.play();
          };
        } else {
          console.error('El elemento <video> no se pudo encontrar en el DOM.');
        }
      });
    }
  } catch (error) {
    console.error('Error inesperado al intentar acceder a la cámara:', error);
    alert('Ocurrió un error al intentar acceder a la cámara. Verifica los permisos y el dispositivo.');
  }
},



async takePicture() {
  try {
    const video = this.$refs.video;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Dimensiones del cuadro cuadrado (512x512)
    const containerSize = 512;
    canvas.width = containerSize;
    canvas.height = containerSize;

    // Establecer el fondo blanco
    context.fillStyle = 'white';
    context.fillRect(0, 0, canvas.width, canvas.height);

    // Calcular el área del video que se recorta para ajustarse al cuadro cuadrado
    const videoAspectRatio = video.videoWidth / video.videoHeight;
    const containerAspectRatio = 1; // Cuadro es siempre 1:1 (512x512)

    let sourceX = 0, sourceY = 0, sourceWidth = video.videoWidth, sourceHeight = video.videoHeight;

    if (videoAspectRatio > containerAspectRatio) {
      // El video es más ancho que el cuadro
      sourceWidth = video.videoHeight * containerAspectRatio;
      sourceX = (video.videoWidth - sourceWidth) / 2;
    } else if (videoAspectRatio < containerAspectRatio) {
      // El video es más alto que el cuadro
      sourceHeight = video.videoWidth / containerAspectRatio;
      sourceY = (video.videoHeight - sourceHeight) / 2;
    }

    // Dibujar el área recortada del video dentro del canvas
    context.drawImage(
      video,
      sourceX,
      sourceY,
      sourceWidth,
      sourceHeight,
      0,
      0,
      canvas.width,
      canvas.height
    );

    // Obtén la imagen como blob
    const blob = await new Promise((resolve) =>
      canvas.toBlob(resolve, 'image/jpeg', 1)
    );

    if (!blob) {
      throw new Error('No se pudo generar la imagen. Por favor, inténtalo nuevamente.');
    }

    // Apagar la cámara y cerrar el modal
    this.closeCamera();

    // Envía la imagen al servidor
    this.sendImageToServer(blob);
  } catch (error) {
    console.error('Error al capturar la imagen:', error);
    alert('Ocurrió un error al tomar la foto. Por favor, inténtalo nuevamente.');
  }
},


async loadImage(file) {
  const reader = new FileReader();
  reader.onload = (e) => {
    const img = new Image();
    img.onload = () => {
      this.imageElement = img;

      // Calcular el tamaño visible del canvas basado en la pantalla
      const maxSide = this.visibleCanvasSize; // Usar el tamaño visible en lugar de forzar 512px
      let newWidth, newHeight;

      if (img.width > img.height) {
        newWidth = maxSide;
        newHeight = (img.height / img.width) * maxSide;
      } else {
        newHeight = maxSide;
        newWidth = (img.width / img.height) * maxSide;
      }

      // Crear un canvas temporal ajustado al tamaño visible
      const tempCanvas = document.createElement("canvas");
      tempCanvas.width = maxSide;
      tempCanvas.height = maxSide;

      const ctx = tempCanvas.getContext("2d");

      // Establecer el fondo blanco ANTES de dibujar la imagen
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

      // Dibujar la imagen redimensionada en el centro del canvas
      const offsetX = (tempCanvas.width - newWidth) / 2;
      const offsetY = (tempCanvas.height - newHeight) / 2;
      ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

      // Convertir el canvas a una nueva imagen
      const resizedImg = new Image();
      resizedImg.onload = () => {
        this.imageElement = resizedImg;

        // Escalar y centrar la imagen en el canvas principal
        this.baseScale = 1; // Escala inicial basada en el tamaño visible
        this.zoom = 1;
        this.offsetX = 0; // Centrar la imagen dentro del canvas
        this.offsetY = 0;

        this.updateCanvas();
      };
      resizedImg.src = tempCanvas.toDataURL("image/jpeg");
    };
    img.src = e.target.result;
  };
  reader.readAsDataURL(file);
},







resetEditor() {
  this.zoom = 1; // Volver al zoom inicial
  this.displayWidth = this.imageElement.width * this.baseScale;
  this.displayHeight = this.imageElement.height * this.baseScale;

  // Centrar la imagen
  this.offsetX = (this.canvasSize - this.displayWidth) / 2;
  this.offsetY = (this.canvasSize - this.displayHeight) / 2;

  this.constrainOffsets(); // Asegurar límites
  this.updateCanvas(); // Actualizar el canvas
},


constrainDrag(pos) {
  const scaledWidth = this.imageElement.width * this.zoom;
  const scaledHeight = this.imageElement.height * this.zoom;

  const minX = Math.min(0, this.canvasSize - scaledWidth);
  const maxX = Math.max(this.canvasSize - scaledWidth, 0);

  const minY = Math.min(0, this.canvasSize - scaledHeight);
  const maxY = Math.max(this.canvasSize - scaledHeight, 0);

  return {
    x: Math.min(Math.max(pos.x, minX), maxX),
    y: Math.min(Math.max(pos.y, minY), maxY),
  };
},


    adjustCanvasSize() {
      // Ajustar el tamaño del canvas dependiendo del tamaño de la pantalla
      const screenSize = Math.min(window.innerWidth, window.innerHeight);
      this.canvasSize = Math.max(Math.min(screenSize, 512), 300); // Máximo 512px, mínimo 300px
      this.offsetX = 0;
      this.offsetY = 0;
      this.zoom = 1;
    },
    onWindowResize() {
      // Recalcular el tamaño del canvas al cambiar el tamaño de la ventana
      this.adjustCanvasSize();
      this.updateCanvas();
    },

    handleWheel(e) {
  e.evt.preventDefault();
  const stage = this.$refs.stage.getStage();
  const pointer = stage.getPointerPosition();

  const scaleBy = 1.1;
  const oldZoom = this.zoom;
  const newZoom = e.evt.deltaY > 0 ? oldZoom / scaleBy : oldZoom * scaleBy;

  this.zoom = Math.min(Math.max(newZoom, 1), 3); // Limitar zoom entre 1 y 3

  const zoomRatio = this.zoom / oldZoom;
  this.offsetX = pointer.x - (pointer.x - this.offsetX) * zoomRatio;
  this.offsetY = pointer.y - (pointer.y - this.offsetY) * zoomRatio;

  this.constrainOffsets(); // Asegurar límites
  this.updateCanvas();
},





    updatePosition(e) {
      const image = e.target;
      this.offsetX = image.x();
      this.offsetY = image.y();

      // Restringir los límites después de mover la imagen
      this.constrainOffsets();
    },
    updateZoom() {
  const imageNode = this.$refs.image?.getNode?.();
  const stage = this.$refs.stage?.getStage?.();

  if (!imageNode || !stage) {
    console.error("VImage o stage no están definidos");
    return;
  }

  // Obtener el centro del canvas como punto de referencia para el zoom
  const pointer = {
    x: this.canvasSize / 2,
    y: this.canvasSize / 2,
  };

  // Calcular el ratio del zoom
  const oldZoom = imageNode.scaleX(); // El zoom anterior
  const zoomRatio = this.zoom / oldZoom;

  // Ajustar los desplazamientos para que el centro del zoom se mantenga en el mismo lugar
  this.offsetX = pointer.x - (pointer.x - this.offsetX) * zoomRatio;
  this.offsetY = pointer.y - (pointer.y - this.offsetY) * zoomRatio;

  // Restringir los límites para evitar que la imagen se salga del canvas
  this.constrainOffsets();

  // Actualizar los atributos del nodo de la imagen
  imageNode.setAttrs({
    scaleX: this.zoom,
    scaleY: this.zoom,
    x: this.offsetX,
    y: this.offsetY,
  });

  // Redibujar el canvas
  const layer = stage.findOne("Layer");
  if (layer) {
    layer.batchDraw();
  }
},



    // Descarga la imagen capturada
    downloadImage(blob) {
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'captured-image.jpg';
      link.click();
      URL.revokeObjectURL(url);
    },

    closeCamera() {
      this.cameraActive = false;
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track) => track.stop());
        this.videoStream = null;
      }
    },
    closeFile() {
      this.showImageEditor = false;
      
    },
    triggerFileInput() {
        const fileInput = this.$refs.galleryFileInput;
        if (fileInput) {
          fileInput.accept = "image/*"; // Permitir solo imágenes
          fileInput.click(); // Abrir el selector de archivos
        }
      },



    handleImageSelection(event) {
      const file = event.target.files[0];
      console.log('Archivo seleccionado:', file); // Log para confirmar el archivo seleccionado
      if (file) {
        this.imageFile = file;
        this.loadImage(file); // Carga la imagen
        this.openImageEditor(); // Abre el modal del editor
      } else {
        console.warn('No se seleccionó ningún archivo');
      }
    },


openImageEditor() {
  console.log("Abriendo editor de imagen"); // Log para confirmar ejecución
  this.showImageEditor = true; // Mostrar el modal

  this.$nextTick(() => {
    const stage = this.$refs.stage?.getStage?.();
    if (!stage) {
      console.error("El stage no está disponible en el DOM");
      return;
    }
    // this.canvasContext = stage.getContext("2d");
    // Realiza acciones con el stage
  });

},



    setupGestures(canvas) {
  if (this.hammerInstance) {
    this.hammerInstance.destroy();
  }

  this.hammerInstance = new Hammer(canvas);

  // Movimiento (pan)
  this.hammerInstance.on("pan", (event) => {
    // Ajustar el movimiento proporcional al zoom
    this.offsetX += event.deltaX / this.zoom;
    this.offsetY += event.deltaY / this.zoom;

    // Restringir los límites después de mover
    this.constrainOffsets(canvas);

    this.updateCanvas();

    // Reiniciar deltas acumulativos
    event.srcEvent.preventDefault();
  });

  // Zoom (pinch)
  this.hammerInstance.get("pinch").set({ enable: true });
  this.hammerInstance.on("pinch", (event) => {
    const newZoom = this.zoom * event.scale;

    // Limitar el zoom entre 1x y 3x
    this.zoom = Math.min(Math.max(newZoom, 1), 3);

    // Ajustar desplazamientos según el nuevo nivel de zoom
    this.constrainOffsets(canvas);

    this.updateCanvas();

    // Reiniciar escala acumulativa
    event.srcEvent.preventDefault();
  });
},
constrainOffsets() {
  const scaledWidth = this.imageElement.width * this.zoom;
  const scaledHeight = this.imageElement.height * this.zoom;

  const canvasWidth = this.visibleCanvasSize;
  const canvasHeight = this.visibleCanvasSize;

  // Calcular límites basados en las dimensiones de la imagen escalada
  const minX = Math.min(0, canvasWidth - scaledWidth);
  const maxX = Math.max(0, canvasWidth - scaledWidth);

  const minY = Math.min(0, canvasHeight - scaledHeight);
  const maxY = Math.max(0, canvasHeight - scaledHeight);

  // Ajustar los desplazamientos dentro de los límites calculados
  this.offsetX = Math.max(minX, Math.min(this.offsetX, maxX));
  this.offsetY = Math.max(minY, Math.min(this.offsetY, maxY));
},




updateCanvas() {
  const stage = this.$refs.stage?.getStage?.();
  if (!stage) return;

  const layer = stage.findOne("Layer");
  const imageNode = this.$refs.image?.getNode?.();

  if (layer && imageNode) {
    imageNode.setAttrs({
      x: this.offsetX,
      y: this.offsetY,
      scaleX: this.zoom,
      scaleY: this.zoom,
    });
    layer.batchDraw();
  }
},




confirmCrop() {
  const stage = this.$refs.stage.getStage();
  
  // Relación entre el tamaño visible y el tamaño real (512x512)
  const scaleFactor = 512 / this.visibleCanvasSize;

  const dataURL = stage.toDataURL({
    pixelRatio: scaleFactor, // Escalar al tamaño deseado
    mimeType: "image/jpeg",
  });

  const blob = this.dataURLtoBlob(dataURL, "image/jpeg");
  this.showImageEditor = false;

  // Enviar al servidor o procesar la imagen
  this.sendImageToServer(blob);
},


dataURLtoBlob(dataURL, mimeType) {
  const byteString = atob(dataURL.split(',')[1]); // Decodificar la base64
  const arrayBuffer = new ArrayBuffer(byteString.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < byteString.length; i++) {
    uint8Array[i] = byteString.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type: mimeType }); // Crear el blob con el tipo especificado
},
closeEditor() {
      this.showImageEditor = false;
      this.imageFile = null;
      this.zoom = 1;
      this.offsetX = 0;
      this.offsetY = 0;

      if (this.hammerInstance) {
        this.hammerInstance.destroy();
        this.hammerInstance = null;
      }
    },
  handleText(index) {
    this.clearCurrentInterval(); // Limpia el intervalo actual antes de comenzar otro
    this.eraseMessage(() => {
      this.typeNextMessage(index);
    });
  },

  clearCurrentInterval() {
    if (this.currentInterval) {
      clearInterval(this.currentInterval);
      this.currentInterval = null;
    }
  },

  eraseMessage(callback) {
    let i = this.modalMessage.length;
    this.currentInterval = setInterval(() => {
      if (i > 0) {
        this.modalMessage = this.modalMessage.substring(0, i - 1);
        i--;
      } else {
        this.clearCurrentInterval();
        if (callback) callback(); // Ejecuta el callback después de borrar
      }
    }, 15);
  },

  typeNextMessage(index) {
    const messages = ['','Procesando imagen', 'Buscando productos similares', 'Mostrando productos'];
    let i = 0;
    this.currentInterval = setInterval(() => {
      if (i < messages[index].length) {
        this.modalMessage += messages[index].charAt(i);
        i++;
      } else {
        this.clearCurrentInterval();
      }
    }, 30);
  },

  
  async sendImageToServer(blob) {
    // const file = event.target.files[0];
    if (!blob) {
      alert("El blob no se generó correctamente.");
      return;
    }
      this.showModal = true; // Muestra el modal de progreso
      this.originalImage = URL.createObjectURL(blob); // Guarda la imagen original

      // Inicia la animación de texto con el primer mensaje
      this.handleText(1); // "Procesando imagen"

      const formData = new FormData();
      formData.append('file', blob, 'image.jpg');
      // const formData = new FormData();
      // formData.append('file', file);
  // Cambia el mensaje
      setTimeout(() => this.handleText(2), 1000); // "Buscando productos similares"
      setTimeout(() => this.handleText(3), 2500); // "Mostrando productos"

      try {

        // Hace una única petición a /clean_image
        const response = await fetch('/api/clean_image', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Failed to process the image: ${errorText}`);
        }

        // Recibe los productos similares directamente desde la respuesta de /clean_image
        const similarImages = await response.json();

        // Oculta el modal y reinicia las animaciones después de mostrar los resultados
        setTimeout(() => {
          this.showModal = false; // Oculta el modal
          this.handleText(0); // Reinicia el texto
          setTimeout(() => {
            this.originalImage = ''; // Limpia la imagen original
            this.processedImage = null; // Limpia la imagen procesada
            this.imageLoaded = false; // Reinicia el estado de la imagen
          }, 100);
        }, 300);

        // Actualiza los resultados de búsqueda
        this.setImageSearchResults(similarImages);

        // Navega a la página de resultados
        this.$router.push({
          name: 'SearchResults',
          query: { searchTerm: 'busqueda-inteligente' },
        });

      } catch (error) {
        console.error('Error processing the image:', error);
      }
    
  }
,

    performSearch() {
      if (this.searchTerm.length >= 3) {
        // Limpia los resultados de búsqueda de imagen
        // // alert('Performing search'); // Alert para depurar
        this.clearImageSearchResults();
        this.$router.push({ name: 'SearchResults', query: { searchTerm: this.searchTerm } });
        
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.buscador
  display: flex
  align-items: center
  justify-content: center
  position: relative
  width: 65%
  max-width: 522px
  @media screen and (min-width: 1200px)
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: auto
  .iconos
    position: absolute
    z-index: 2
    top: 0
    bottom: 0
    left: 20px
    right: auto
    margin: auto
    display: flex    
    align-items: center
    .icono-cam			
      display: flex
      align-items: center
      cursor: pointer    
      margin-right: 20px      
    .icono-file      
      display: flex
      align-items: center
      cursor: pointer
  .buscador-input                
    border: none
    border-radius: 30px
    width: 100%
    max-width: 522px
    padding-left: 100px
    margin-top: 0
    margin-bottom: 0
    height: 35px
    @media screen and (min-width: 768px) 
      height: 45px
  .icono-buscar			
    position: absolute
    z-index: 2
    top: 0
    bottom: 0
    right: 20px
    left: auto
    margin: auto
    display: flex
    align-items: center
    cursor: pointer

.modal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  // margin: auto
  background-color: rgba(0, 0, 0, 0.7)
  display: flex
  justify-content: center
  align-items: center
  z-index: 3
  height: 100vh
  width: 100vw
.modal-overlay-file
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  // margin: auto
  background-color: rgba(0, 0, 0, 1)
  display: flex
  justify-content: center
  align-items: center
  flex-direction: column
  z-index: 3
  height: 100vh
  width: 100vw
  padding-bottom: 20px

.modal
  background-color: white
  padding: 20px
  border-radius: 10px
  text-align: center
  max-width: 400px  
  width: 90%
  .caja-img
    position: relative
    max-width: 360px
    width: 100%
    height: 360px
    overflow: hidden
    .base-image
      position: absolute
      top: 0
      left: 0
      max-width: 100%
      
      height: 100%
      right: 0
      margin: auto
      object-fit: contain
      border-radius: 15px
.image-transition
  position: absolute
  top: 0
  left: 0
  max-width: 100%
  height: 100%
  object-fit: cover
  border-radius: 15px
  transition: opacity 0.5s ease-in-out
  opacity: 0  // Inicia invisible
.fade-enter-active, .fade-leave-active
  transition: opacity 0.5s
.fade-enter, .fade-leave-to
  opacity: 0
.fade-enter-to
  opacity: 1
.persist-opacity 
  opacity: 1 !important  // Uso de !important para asegurar que la opacidad se mantenga

p
  margin-top: 10px
  color: #3C1053
  font-weight: bold
  min-height: 34px

.camera-screen 
  position: fixed
  height: 100vh
  width: 100vw
  background: black
  display: flex
  justify-content: center
  align-items: center
  top: 0
  bottom: 0
  left: 0
  right: 0
  margin: auto
  z-index: 10000
  svg 
    min-width: 25px
    min-height: 25px
    fill: rgba(130, 70, 175, 0.95)
    max-width: 40px
  button
    background: #ffffff
    box-shadow: inset 13px 13px 20px #b5b5b5, inset -13px -13px 20px #ffffff
    display: flex
    align-items: center
    justify-content: center


.video-container 
  position: relative
  width: 100%
  max-width: 512px
  aspect-ratio: 1
  background: black
  overflow: hidden


video 
  position: absolute
  top: 50%
  left: 50%
  width: 100%
  height: 100%
  object-fit: cover
  transform: translate(-50%, -50%)
  border-radius: 15px 
  overflow: hidden

.overlay 
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  // border: 4px solid white
  pointer-events: none
  z-index: 2
  border-radius: 15px 
  overflow: hidden


.capture-button
  position: absolute
  left: 50%
  transform: translateX(-50%)
  background-color: white
  border: none
  border-radius: 50%
  width: 60px
  height: 60px
  cursor: pointer
  z-index: 3
  color: #000
  svg
    max-width: 30px
.capture-button-cut
  position: relative
  left: 50%
  transform: translateX(-50%)
  background-color: white
  border: none
  border-radius: 50%
  width: 60px
  height: 60px
  cursor: pointer
  z-index: 3
  color: #000
  margin-top: 20px
  svg
    max-width: 30px
.switch-camera-button
  position: absolute
  right: 5px
  bottom: 5px
  // transform: translateX(-50%)
  background-color: white
  border: none
  border-radius: 50%
  width: 60px
  height: 60px
  cursor: pointer
  z-index: 3
  color: #000
.close-camera-button
  position: absolute
  right: 5px
  top: 5px
  // transform: translateX(-50%)
  background-color: white
  border: none
  border-radius: 50%
  width: 55px
  height: 55px
  cursor: pointer
  z-index: 3
  color: #000
  svg
    max-width: 30px
.close-file-button
  position: absolute
  right: 5px
  top: 5px
  // transform: translateX(-50%)
  background-color: white
  border: none
  border-radius: 50%
  width: 55px
  height: 55px
  cursor: pointer
  z-index: 3
  color: #000
  svg
    max-width: 30px
.return-button
  position: absolute
  left: 5px
  bottom: 5px
  // transform: translateX(-50%)
  background-color: white
  border: none
  border-radius: 50%
  width: 55px
  height: 55px
  cursor: pointer
  z-index: 3
  color: #000
  svg
    max-width: 30px

.capture-button 
  bottom: 10vh
// .switch-camera-button 
//   bottom: 20%
//   background-color: #f0f0f0
.overlay-mask
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  background: rgba(130, 70, 175, 0.7)
  mask: radial-gradient(circle at center, transparent 65%, black 41%)
  -webkit-mask: radial-gradient(circle at center, transparent 65%, black 41%)
  z-index: 2
  pointer-events: none
  border-radius: 15px 
  overflow: hidden
// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background: rgba(0, 0, 0, 0.7);
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.image-editor 
  display: flex
  flex-direction: column
  align-items: center
  gap: 10px
  position: relative
  border-radius: 15px
  overflow: hidden
  width: 100%
  max-width: 512px
  aspect-ratio: 1
.editor-canvas 
  display: block
  margin: 0 auto
  width: 512px
  height: 512px
  // border-radius: 50%
  // background: black

.zoom-control 
  margin-top: 10px
  width: 80%
.confirm-radio-file 
  width: 95vw
  max-width: 450px
  padding-top: 20px
  
input[type="range"] 
  -webkit-appearance: none
  width: 100%
  height: 8px
  border-radius: 5px
  background: linear-gradient(to right, #8246af, #c49fdd)
  outline: none
  opacity: 0.9
  transition: opacity 0.2s


input[type="range"]:hover 
  opacity: 1


input[type="range"]::-webkit-slider-thumb 
  -webkit-appearance: none
  appearance: none
  width: 30px
  height: 30px
  border-radius: 50%
  background: #fff
  cursor: pointer
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5)
  position: relative
  transform: translateY(-6px)


input[type="range"]::-moz-range-thumb 
  width: 30px
  height: 30px
  border-radius: 50%
  background: #fff
  cursor: pointer
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5)
  position: relative
  transform: translateY(-6px)


input[type="range"]::-ms-thumb 
  width: 30px
  height: 30px
  border-radius: 50%
  background: #fff
  cursor: pointer
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5)
  position: relative
  transform: translateY(-6px)


input[type="range"]::-webkit-slider-runnable-track 
  height: 15px
  border-radius: 5px
  background: linear-gradient(to right, #8246af, #c49fdd)


input[type="range"]::-moz-range-track 
  height: 15px
  border-radius: 5px
  background: linear-gradient(to right, #8246af, #c49fdd)


input[type="range"]::-ms-track 
  height: 15px
  border-radius: 5px
  background: linear-gradient(to right, #8246af, #c49fdd)
  border: none


</style>
